import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { api } from "~/hooks/api";

import { Container } from "./styles";
import { useAuth } from "~/hooks/useAuth";
import FinishRegister from "../../components/FinishRegister";

const AssignorFinish: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [warnings, setWarnings] = useState<
    {
      title: string;
      description: string;
    }[]
  >([]);
  const [validations, setValidations] = useState<
    {
      title: string;
      description: string;
    }[]
  >([]);

  const { currentAssignor } = useAuth();

  useEffect(() => {
    async function loadData() {
      let arrW: any[] = [];
      let arrV: any[] = [];
      try {
        const { data } = await api.get("/register/assignor");
        if (!data.name) {
          arrV.push({
            title: "Razão Social [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.cnpj) {
          arrV.push({
            title: "CNPJ [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.fantasy_name) {
          arrV.push({
            title: "Nome Fantasia [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.foundation_date) {
          arrV.push({
            title: "Data de Fundação [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.email) {
          arrV.push({
            title: "E-mail [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.zip_code) {
          arrV.push({
            title: "CEP [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.street) {
          arrV.push({
            title: "Logradouro [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.district) {
          arrV.push({
            title: "Bairro [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.city) {
          arrV.push({
            title: "Cidade [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.state) {
          arrV.push({
            title: "UF [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.number) {
          arrV.push({
            title: "Número [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.phone) {
          arrV.push({
            title: "Telefone [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.cellphone) {
          arrV.push({
            title: "Celular [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.share_capital) {
          arrV.push({
            title: "Capital Social [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.revenues) {
          arrV.push({
            title: "Faturamento [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
        if (!data.create_date) {
          arrV.push({
            title: "Data de cadastro [Informações Gerais]",
            description: "Campo incompleto ou inválido",
          });
        }
      } catch (err) {
        //
      }
      try {
        const { data } = await api.get("/register/guarantors");
        if (data.length === 0) {
          arrW.push({
            title: "Sócios / Avalistas",
            description: "Necessário cadastrar no mínimo 1",
          });
        } else {
          const guarantorDocs = await api.get("/document-types?type=guarantor");
          const guarantorPJDocs = await api.get("/document-types?type=guarantor-pj");

          data.forEach(async (guarantor, index) => {
            let docTypes = guarantorPJDocs;

            if(guarantor?.rg_ie?.length) {
              docTypes = guarantorDocs;
            }
            
            const mandatoriesDocuments = docTypes?.data.filter(
              (documentType) => documentType?.is_mandatory
            );

            if (mandatoriesDocuments?.length > 0) {
              let requiredsDocs = guarantor?.documents?.filter(
                (document) => document?.documentType?.is_mandatory
              ).filter((value, index, self) =>
                index === self.findIndex((obj) => obj.document_type_id === value.document_type_id)
              )

              if (requiredsDocs.length < mandatoriesDocuments?.length) {
                arrW.push({
                  title: "Avalista",
                  description: `Há documento(s) obrigatório(s) não preenchido(s) no avalista ${guarantor?.name}`,
                });
              }
            }

            if (!guarantor.name) {
              arrV.push({
                title: `Nome / Razão Social [Avalista ${index + 1}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.document_number) {
              arrV.push({
                title: `CPF/CNPJ [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.birth_date) {
              arrV.push({
                title: `Data Nascimento / Fundação [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.zip_code) {
              arrV.push({
                title: `CEP [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.number) {
              arrV.push({
                title: `Número [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.phone) {
              arrV.push({
                title: `Telefone [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
            if (!guarantor.rg_ie && guarantor.document_number?.length === 11) {
              arrV.push({
                title: `RG [${guarantor.name}]`,
                description: "Campo incompleto ou inválido",
              });
            }
          });
        }
      } catch (err) {
        //
      }
      // try {
      //   const { data } = await api.get("/register/banks");
      //   if (data.length === 0) {
      //     arrW.push({
      //       title: "Contas Corrente",
      //       description: "Necessário cadastrar no mínimo 1",
      //     });
      //   }
      // } catch (err) {
      // }
      try {
        const { data } = await api.get("/register/financialReferences");
        if (data.length === 0) {
          arrW.push({
            title: "Referências Financeiras",
            description: "Necessário cadastrar no mínimo 1",
          });
        }
      } catch (err) {
        //
      }
      try {
        let documentTypes = await api.get("/document-types?type=assignor");

        let mandatoriesDocumentsIds = documentTypes?.data?.filter(
          (documentType) => documentType?.is_mandatory
        )?.map(mandatoriesDocument => mandatoriesDocument?.id);


        if (mandatoriesDocumentsIds?.length > 0) {
          let documents = await api.get("/register/documents");

          let requiredsDocs = documents?.data?.filter(
            (document) => document?.documentType?.is_mandatory && mandatoriesDocumentsIds?.includes(document?.document_type_id)
          ).filter((value, index, self) =>
            index === self.findIndex((obj) => obj.document_type_id === value.document_type_id)
          ); // sem repetir

          if (requiredsDocs.length !== mandatoriesDocumentsIds?.length) {
            arrW.push({
              title: "Documentos",
              description: "Há documento(s) obrigatório(s) não enviado(s)",
            });
          }
        }
      } catch (err) {
        //
      }

      setWarnings(arrW);
      setValidations(arrV);
    }
    loadData().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Container>
        <Spinner animation="border" /> Carregando
      </Container>
    );
  }

  if (currentAssignor.is_finished) {
    return (
      <Container>
        <h3>Cadastro já finalizado</h3>
        <p>Seu cadastro já consta como finalizado</p>
      </Container>
    );
  }

  if (currentAssignor.rejected) {
    return (
      <Container>
        <h3>Plataforma Desabilitada</h3>
        <p>Plataforma desabilitada. Favor entrar em contato com o seu gerente.</p>
      </Container>
    );
  }

  return (
    <Container>
      {!currentAssignor.is_approved && warnings.length > 0 && (
        <div className="row">
          <div className="col-md-12">
            <Alert severity="warning" className="mb-3">
              {warnings.map((w, index) => (
                <div key={index} className="mb-2">
                  <strong className="d-block">{w.title}</strong>
                  <small>{w.description}</small>
                </div>
              ))}
            </Alert>
          </div>
        </div>
      )}
      {!currentAssignor.is_approved && validations.length > 0 && (
        <div className="row">
          <div className="col-md-12">
            <Alert severity="error" className="mb-3">
              {validations.map((w, index) => (
                <div className="mb-2" key={index}>
                  <strong className="d-block">{w.title}</strong>
                  <small>{w.description}</small>
                </div>
              ))}
            </Alert>
          </div>
        </div>
      )}

      {!currentAssignor.is_approved &&
        (validations.length > 0 || warnings.length > 0) ? (
        <div>
          <p>Conclua as pendências acima para poder finalizar</p>
        </div>
      ) : (
        <FinishRegister/>
      )}
    </Container>
  );
};

export default AssignorFinish;
