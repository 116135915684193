import React, { useState, useEffect } from "react";

import { Form, Row, Col } from "react-bootstrap";

import { Alert } from "@material-ui/lab";
import { Button, CircularProgress } from "@material-ui/core";
import { api } from "~/hooks/api";
import consultCep from "~/services/consultCep";
import {
  normalizeCpfCnpj,
  normalizeCurrency,
  normalizeAccBank,
  normalizeDate,
  normalizeNumber,
  normalizeNumbersAndSpecialCharacters,
  normalizeCep,
  normalizeTelephone,
  cnpjIsValid,
  cpfIsValid,
} from "../../utils/normalize";
import { Container } from "./styles";
import { states } from "~/pages/main/utils/states";

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onAdd: () => void;
}

const ModalTapNote: React.FC<IProps> = ({ open, setOpen, onAdd }) => {
  const [values, setValues] = useState<any>({}); // eslint-disable-line
  const [payerExists, setPayerExists] = useState(true);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [loading, setLoading] = useState(false);

  function resetForm() {
    setValues({});
    setPayerExists(true);
    setOpen(false);
    setError("");
    setSuccess(false);
  }

  function handleNormalize(name: string, value: string) {
    switch (name) {
      case "saca_id":
        return normalizeCpfCnpj(value);
      case "value":
        return normalizeCurrency(Number(value.replace(/[^\d]/g, "")));
      case "titleValue":
        return normalizeCurrency(Number(value.replace(/[^\d]/g, "")));
      case "data_emis":
        return normalizeDate(value);
      case "data_titu":
        return normalizeDate(value);
      case "documentNumber":
        return normalizeAccBank(value);
      case "serie_nota":
        return value.toUpperCase();
      case "chave":
        return value.toUpperCase();
      case "nume_nota":
        return normalizeNumber(value);
      case "cep_id":
        return normalizeCep(value);
      case "fone":
        return normalizeTelephone(value);
      default:
        return value;
    }
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setError("");
    setSuccess(false);
    const { name, value } = e.target;
    setValues({ ...values, [name]: handleNormalize(name, value) });
  }

  async function handleVerifyPayer() {
    setValues({
      nome: "",
      iden: "",
      cep_id: "",
      ende: "",
      nume: "",
      comp: "",
      bair: "",
      cida: "",
      uf: "",
      fone: "",
      e_mail: "",
    });
    try {
      const { data } = await api.get(
        `payers/document_number/${values.saca_id.replace(/[^\d]/g, "")}`
      );
      setValues({
        ...values,
        nome: data.name,
        saca_id: data.document_number,
        iden: data.ie_rg,
        cep_id: data.zip_code,
        ende: data.street,
        nume: data.number,
        comp: data.complement,
        bair: data.district,
        cida: data.city,
        uf: data.state,
        fone: data.phone,
        e_mail: data.email,
      });
      setPayerExists(true);
    } catch (err) {
      setValues({
        ...values,
        saca_id: values.saca_id,
        nome: "",
        iden: "",
        cep_id: "",
        ende: "",
        nume: "",
        comp: "",
        bair: "",
        cida: "",
        uf: "",
        fone: "",
        e_mail: "",
      });
      setPayerExists(false);
    }
  }

  async function handleCepAddress() {
    const consult = await consultCep(values.cep_id.replace("-", ""));
    if (consult) {
      setValues({
        ...values,
        cep_id: values.cep_id.split("-").join(""),
        ende: consult.logradouro,
        bair: consult.bairro,
        cida: consult.cidade,
        uf: consult.estado,
        nume: "",
        comp: "",
      });
      document.getElementsByName("nume")[0].focus();
    } else {
      setValues({
        ...values,
        cep_id: "",
        ende: "",
        bair: "",
        cida: "",
        uf: "",
        nume: "",
        comp: "",
      });
    }
  }

  function parseToDate(value: string) {
    const splitedDate = value.split("/");
    return new Date(`${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`);
  }

  async function handleSubmit(
    e: React.FormEvent<HTMLFormElement>
  ): Promise<any> {
    // eslint-disable-line
    console.log('AQUI')
    e.preventDefault();
    console.log('1')
    console.log('2')
    if (!values.data_emis || values.data_emis.length < 10) {
      console.log('3')
      return setError("Data de emissão");
    }
    if (!values.data_titu || values.data_titu.length < 10) {
      console.log('4')
      return setError("Data de vencimento da nota inválida");
    }
    if (normalizeNumber(values.saca_id).length === 11) {
      console.log('5')
      if (!cpfIsValid(normalizeNumber(values.saca_id))) {
        console.log('6')
        return setError("CPF Inválido");
      }
    } else if (normalizeNumber(values.saca_id).length === 14) {
      console.log('7')
      if (!cnpjIsValid(normalizeNumber(values.saca_id))) {
        console.log('8')
        return setError("CNPJ Inválido");
      }
    }
    setLoading(true);
    console.log('9')
    try {
      const body = {
        key: values.chave,
        serie: values.serie_nota,
        nfse_number: values.nume_nota,
        duplicate_number: values.number,
        emission_date: parseToDate(values.data_emis),
        duplicate_date: parseToDate(values.data_titu),
        value: Number(values.value.replace(/[^\d]/g, "")),
        payer: {
          name: values.nome,
          document_number: values.saca_id.replace(/[^\d]/g, ""),
          ie_rg: values.iden,
          zip_code: values.cep_id,
          street: values.ende,
          number: values.nume,
          complement: values.comp,
          district: values.bair,
          city: values.cida,
          state: values.uf,
          phone: values.fone ? values.fone.replace(/[^\d]/g, "") : undefined,
          email: values.e_mail,
        },
      };
      console.log('10')
      await api.post("nfses", body);
      console.log('11')
      const emptyValues: any = {};
      Object.keys(values).forEach((i) => {
        emptyValues[i] = "";
      });
      console.log('12')
      setValues(emptyValues);
      console.log('13')
      setPayerExists(true);
      console.log('14')
      setSuccess(true);
      console.log('15')
      onAdd();
      console.log('16')
      setRefreshing(true);
      console.log('16')
    } catch (err) {
      console.log('erro', err.message)
      //
    }
    return setLoading(false);
  }

  useEffect(() => {
    if (refreshing) {
      setTimeout(() => {
        setRefreshing(false);
      }, 500);
    }
  }, [refreshing]);

  return (
    <Container size="lg" show={open} onHide={resetForm} centered>
      {!refreshing && (
        <Form onSubmit={handleSubmit}>
          <Container.Body>
            {error !== "" && (
              <Alert severity="error" className="mb-2">
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" className="mb-2">
                NFS-e adicionada com sucesso
              </Alert>
            )}
            <small>Dados do Tomador</small>
            <Row>
              <Col lg={4}>
                <Form.Group>
                  <Form.Label>CNPJ/CPF</Form.Label>
                  <Form.Control
                    name="saca_id"
                    value={values.saca_id}
                    onChange={handleChange}
                    disabled={loading}
                    onBlur={handleVerifyPayer}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={8}>
                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    name="nome"
                    value={values.nome}
                    onChange={handleChange}
                    disabled={payerExists || loading}
                    required
                  />
                </Form.Group>
              </Col>
              {!payerExists && (
                <>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>RG/CGF</Form.Label>
                      <Form.Control
                        name="iden"
                        value={values.iden}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        name="cep_id"
                        value={values.cep_id}
                        onChange={handleChange}
                        disabled={loading}
                        onBlur={handleCepAddress}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        name="ende"
                        value={values.ende}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>Número</Form.Label>
                      <Form.Control
                        name="nume"
                        value={values.nume}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        name="comp"
                        value={values.comp}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        name="bair"
                        value={values.bair}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        name="cida"
                        value={values.cida}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>UF</Form.Label>
                      <Form.Control
                        name="uf"
                        as="select"
                        value={values.uf}
                        onChange={handleChange}
                        disabled={loading}
                      >
                        <option /> {/* eslint-disable-line */}
                        {states.map((s) => (
                          <option key={s.uf} value={s.uf}>
                            {s.desc}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        name="fone"
                        value={values.fone}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="email"
                        name="e_mail"
                        value={values.e_mail}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
            <br />
            <small>Dados da NFS-e</small>
            <Row>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Série</Form.Label>
                  <Form.Control
                    name="serie_nota"
                    value={values.serie_nota}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group>
                  <Form.Label>Cod de Verificação</Form.Label>
                  <Form.Control
                    name="chave"
                    value={values.chave}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Nº da Nota</Form.Label>
                  <Form.Control
                    name="nume_nota"
                    value={values.nume_nota}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={2}>
                <Form.Group>
                  <Form.Label>Documento</Form.Label>
                  <Form.Control
                    name="number"
                    value={normalizeNumbersAndSpecialCharacters(values.number)}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group>
                  <Form.Label>Data de emissão</Form.Label>
                  <Form.Control
                    name="data_emis"
                    value={values.data_emis}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group>
                  <Form.Label>Data de vencimento</Form.Label>
                  <Form.Control
                    name="data_titu"
                    value={values.data_titu}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group>
                  <Form.Label>Valor do Título</Form.Label>
                  <Form.Control
                    name="value"
                    value={values.value}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container.Body>
          <Container.Footer>
            <div className="d-flex justify-content-between">
              {loading ? <CircularProgress /> : <span />}
              <div>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                >
                  Enviar
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={resetForm}
                  className="ml-2"
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Container.Footer>
        </Form>
      )}
    </Container>
  );
};

export default ModalTapNote;
